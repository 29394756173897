import React from "react"
import { withPrefix, Link, graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import dateFormat from "dateformat"
import styles from "../styles.css"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allContentfulPost.edges

    return (
      <div class="mainColumn">

        <Layout location={this.props.location} title={siteTitle}>
        
        <SEO title="Home" />
        
        {posts.map(({ node }) => {
          const title = node.title || node.slug
          return (
            <div key={node.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.slug}>
                  {title}
                </Link>
              </h3>
              <p>{
                dateFormat(node.updatedAt, "mmmm dS, yyyy")
                }</p>
            </div>
          )
        })}
      </Layout>
      <Helmet>
          <script src={withPrefix('comm100.js')} type="text/javascript" />
          <meta name="description" content="Hi! I'm Cale Shapera! Here you'll find my blog and some projects that I've done." />
      </Helmet>
      </div>
      
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost {
      edges {
        node {
          title
          subtitle
          author
          slug
          updatedAt
        }
      }
    }
  }
`
